/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import HomeWeb from '../Pages/HomeWeb';
import Details from '../Pages/Details';
import Item from '../Pages/Item';
import Address from '../Pages/Address';
import ShoppingCart from '../Pages/ShoppingCart';
import AddressList from '../Pages/AddressList';
import TimelineItem from '../Pages/TimelineItem';
import OrderDetails from '../Pages/OrderDetails';
import NavBar from '../Components/Navbar';
import RedirectComponent from '../util/Redirect';
import AuthRedirectComponent from '../util/Auth';
import AuthStep from '../Pages/AuthStep';
import ViewNavFooter from '../Components/BagButton';
import OrderResume from '../Pages/OrderResume';
import PreHome from '../Pages/PreHome';
import CitiesPreHome from '../Pages/CitiesPreHome';
import OrderList from '../Pages/OrderList';
import AuthRedirectOrderList from '../util/OrderAuth';
import AuthOrderList from '../Pages/AuthOrderList';
import { Home as HomeFrame, Details as DetailsFrame, Item as ItemFrame } from '../Pages/WebFrame';
import CheckoutOrderDetails from '../Pages/CheckoutOrderDetails';

const MobileRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Route path="/" exact component={PreHome} />
      <Route path="/estabelecimentos/:city" exact component={CitiesPreHome} />
      <Route path="/:storeid/app" exact component={HomeFrame} />
      <Route path="/:storeid/app/details/:id" exact component={DetailsFrame} />
      <Route path="/:storeid/app/item/:id" exact component={ItemFrame} />
      <Route path="/:storeid" component={NavBar} />
      <Route path="/:storeid" exact component={isMobile ? Home : HomeWeb} />
      {isMobile ? (
        <Route path="/:storeid" exact component={ViewNavFooter} />
      ) : (
        <Route path="/:storeid/app" exact component={ViewNavFooter} />
      )}
      <Route path="/:storeid/" exact component={RedirectComponent} />
      <Route path="/:storeid/details/:id" exact component={Details} />
      {isMobile ? (
        <Route path="/:storeid/details/:id" exact component={ViewNavFooter} />
      ) : (
        <Route path="/:storeid/app/details/:id" exact component={ViewNavFooter} />
      )}
      <Route path="/:storeid/item/:id" exact component={Item} />
      {isMobile ? (
        <Route path="/:storeid/item/:id" exact component={ViewNavFooter} />
      ) : (
        <Route path="/:storeid/app/item/:id" exact component={ViewNavFooter} />
      )}
      <Route path="/:storeid/address-list" exact component={AddressList} />
      <Route path="/:storeid/address" exact component={Address} />
      <Route path="/:storeid/cart" exact component={ShoppingCart} />
      <Route path="/:storeid/auth" exact component={AuthRedirectComponent} />
      <Route path="/:storeid/login" exact component={AuthStep} />
      <Route path="/:storeid/resume" exact component={OrderResume} />
      <Route path="/:storeid/timeline/:orderId" exact component={TimelineItem} />
      <Route path="/:storeid/order-details/:orderId" exact component={OrderDetails} />
      <Route path="/:storeid/orderlist" exact component={OrderList} />
      <Route path="/:storeid/authorderlist" exact component={AuthRedirectOrderList} />
      <Route path="/:storeid/loginorderlist" exact component={AuthOrderList} />
      <Route path="/:storeid/checkout-detail" exact component={CheckoutOrderDetails} />
    </BrowserRouter>
  );
};

export default MobileRoutes;
