/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Carousel from 'react-material-ui-carousel';
import Loader from 'react-loader-spinner';
import { GridList, GridListTile, GridListTileBar, Typography, Grid } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Footer, useStyles } from './styles';
import api from '../../service/api';
import Logo from '../../assets/Logo.png';
import { NotificationClosedStore } from '../../Components/NotificationClosedStore';

type IRouteParams = {
  id: string;
  storeid: string;
};

interface IProductData {
  map(arg0: (product: IProductData) => JSX.Element): React.ReactNode;
  id: string;
  typecast: string;
  name: string;
  description: string;
  content: null;
  image: string;
  ordernation: number;
  externalCode: string;
  price: string;
  productId: string;
  minimum: number;
  calculation: string;
  status: string;
  primaryLevel: string;
  img: null;
  complementPagination: string;
}

interface IFeaturedBanner {
  map(arg0: (imageSrc: IFeaturedBanner) => JSX.Element): React.ReactNode;
  id: string;
  img_link: string;
}

const Home: React.FC = () => {
  const params = useParams<IRouteParams>();
  const [productsData, setProductsData] = useState<IProductData | undefined>(undefined);
  const [featuredBanner, setFeaturedBanner] = useState<IFeaturedBanner | undefined>(undefined);
  const classes = useStyles();
  const [storeName, setStoreName] = useState<string>('');
  const [deliveryActive, setDeliveryActive] = useState<boolean>(false);
  const dispatch = useDispatch();
  const closedStore = useSelector((state: any) => state.store);
  const [checkStoreAction, setCheckStoreAction] = useState<boolean>(false);

  if (window.location.pathname === `/${params.storeid}`) {
    useScrollPosition(({ currPos }) => {
      localStorage.setItem(`Scroll${params.storeid}`, JSON.stringify(currPos.y));
    });
  }

  const posY = localStorage.getItem(`Scroll${params.storeid}`);

  if (posY) {
    const parsedPos = parseInt(posY, 10);
    setTimeout(() => {
      window.scrollTo(0, Math.abs(parsedPos));
    }, 500);
  }

  useEffect(() => {
    if (window.location.pathname === '/pizzaprimavera' || window.location.pathname === '/botecoprimavera') {
      window.location.href = 'https://www.meupedido.online/primavera';
    }
  }, [params.storeid]);

  useEffect(() => {
    async function handleProducts(): Promise<void> {
      api.get(`/products/${params.storeid}/primary`).then((response) => {
        setProductsData(response.data);
      });
    }
    handleProducts();
    async function handleFeaturedBanner(): Promise<void> {
      api.get(`/stores/featuredbanner/${params.storeid}`).then((response) => {
        const result = response.data;
        setFeaturedBanner(result);
      });
    }
    handleFeaturedBanner();
  }, [params.storeid]);

  useEffect(() => {
    const store = { storeName: params.storeid };
    async function handlePostViews(): Promise<void> {
      api.post('views', store);
    }
    handlePostViews();
  }, []);

  const handleFriendlylink = () => {
    api.get(`/stores/friendlylink/${params.storeid}`).then((response) => {
      const { data } = response;

      setStoreName(data.name);
      setDeliveryActive(data.deliveryActive);
      data.action === 'open'
        ? dispatch({ type: 'CLOSED_STORE', store: false })
        : dispatch({ type: 'CLOSED_STORE', store: true });
    });
  };

  useEffect(() => {
    handleFriendlylink();
  }, []);

  useEffect(() => {
    const handleCheckStoreAction = async () => {
      await api.get(`/stores/friendlylink/${params.storeid}`).then((response) => {
        const { data } = response;
        const store = data.action === 'open' ? false : true;
        const actionStore = closedStore !== store;
        if (actionStore) {
          handleFriendlylink();
        }
      });
    };
    handleCheckStoreAction();
  }, [checkStoreAction]);

  useEffect(() => {
    let interval = setInterval(() => setCheckStoreAction(!checkStoreAction), 6000);
    return () => clearInterval(interval);
  });

  function setViewHome() {
    return (
      <Container>
        <Grid>
          {!productsData && (
            <>
              <Loader
                style={{
                  marginTop: '-30vh',
                }}
                type="RevolvingDot"
                color="#3e3e3e"
                height={100}
                width={100}
              />
            </>
          )}
          {productsData && (
            <>
              <>
                <GridList key={1} cellHeight={170} className={classes.gridList}>
                  <div
                    style={{
                      position: 'relative',
                      padding: '0px !important',
                      marginTop: closedStore === true && deliveryActive ? '49px' : '0px',
                      marginBottom: closedStore === true && deliveryActive ? '-10px' : 0,
                      // marginBottom: productsData.length <= 4 ? '-80px' : 0,
                      width: '100vw',
                      minHeight: '190px',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        marginTop: '0',
                        width: '100vw',
                        minHeight: '130px',
                      }}
                    >
                      {featuredBanner && (
                        <Carousel autoPlay>
                          {featuredBanner.map((imageSrc: IFeaturedBanner) => (
                            <div>
                              <img
                                style={{ marginTop: '0', width: '100%', minHeight: '130px' }}
                                key={imageSrc.id}
                                src={imageSrc.img_link}
                                alt="Banner"
                              />
                            </div>
                          ))}
                        </Carousel>
                      )}
                    </div>
                  </div>
                  {closedStore === true && deliveryActive ? (
                    <NotificationClosedStore message={`A unidade ${storeName} está fechada no momento.`} />
                  ) : null}
                  <GridListTile cols={2} />
                  {productsData?.map((product: IProductData) => (
                    <GridListTile
                      style={{ overflow: 'scroll' }}
                      id={product.id}
                      component={Link}
                      to={`${params.storeid}/details/${product.id}`}
                      key={product.id}
                    >
                      <img src={product.image} alt={product.name} />
                      <GridListTileBar title={product.name} />
                    </GridListTile>
                  ))}

                  <Footer>
                    <Grid container direction="column" justify="center" alignItems="center">
                      <Grid>
                        <img src={Logo} alt="logo" style={{ height: '12vh' }} />
                      </Grid>
                      <Grid>
                        <Typography style={{ color: '#fff', marginTop: '10px' }}>
                          © Copyright 2021 Meu Pedido Online
                        </Typography>
                      </Grid>
                    </Grid>
                  </Footer>
                </GridList>
              </>
            </>
          )}
        </Grid>
      </Container>
    );
  }

  return <>{setViewHome()}</>;
};

export default Home;
