import styled from 'styled-components';

export const ButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 35px 30px;
  background-color: #181818;
  span {
    color: #fff !important;
    size: 20px !important;
  }
`;
export const BodyContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
`;
export const TableContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  border: 2px solid #dcdcd0;
  box-shadow: 0px 0px 15px #dcdcd0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: calc(100% - 30px);
`;
export const HeaderTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dcdcde;
  width: 100%;
  height: auto;
  padding: 2px;
  span {
    font-size: 14px;
    padding: 20px 0px;
    color: #8a8787 !important;
  }
`;
export const SubHeaderTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  padding: 2px 0px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dashed #dcdcde;
    align-items: center;
    width: 80%;
    height: auto;
    padding: 2px 0px;
  }
  span {
    width: 250px;
    padding: 5px 0px;
    font-size: 8.5px;
    color: #8a8787 !important;
    margin-left: 15px;
  }
`;
export const FooterTableContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-top: 2px solid #dcdcde;
  border-bottom: 2px solid #dcdcde;
  margin-top: 5px;
  width: 100%;
  padding: 2px;
`;
export const HeaderTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dcdcde;
  width: 100%;
  height: auto;
  padding: 2px;
`;
export const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 5px);
  padding: 2.5px 0px;
`;
export const Title = styled.span`
  display: flex;
  width: 30%;
  justify-content: flex-start;
  font-size: 12px;
  text-align: left;
  align-items: center;
  color: #636363;
`;
export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 15px;
`;
export const TextSpecial = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #b9d964 !important;
`;

// Foi trocado pelo FooterTableContainer para evitar redudância.

// export const FooterContainer = styled.footer`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   position: relative;
//   border-top: 2px solid #dcdcde;
//   border-bottom: 2px solid #dcdcde;
//   margin-top: 5px;
//   width: 100%;
// `;
