import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Routes from './routes/index';
import { store, persistor } from './store';

import { GlobalStyle } from './styles';

const App: React.FC = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <Routes />
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
