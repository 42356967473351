/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { CardButton, NameItem, ComplementsText, ItemsComplementsContainer } from './styles';

interface IItemDTO {
  id: string;
  typecast: string;
  name: string;
  description: string;
  content: null;
  image: string;
  ordernation: number;
  externalCode: string;
  price: string;
  productId: string;
  minimum: number;
  calculation: string;
  status: string;
  primaryLevel: string;
  img: null;
  complementPagination: string;
  parentId: string;
  count: number;
  amount?: number;
  complements?: IItemDTO[];
}
const CheckoutOrderDetails = (OrderResume: any) => {
  return (
    <div>
      {OrderResume.OrderResume.map((product: any) => (
        <>
          <CardButton>
            <ItemsComplementsContainer>
              <NameItem>{product.name}</NameItem>
              {product.items?.map((complement: IItemDTO) => (
                <>
                  <ComplementsText>{`- ${complement.name} (${complement.count})`}</ComplementsText>
                </>
              ))}
            </ItemsComplementsContainer>
          </CardButton>
        </>
      ))}
    </div>
  );
};
export default CheckoutOrderDetails;
