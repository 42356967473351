import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { FaShoppingBag } from 'react-icons/fa';
import cash from '../../util/cash';
import { ContainerButton } from './styles';

type IRouteParams = {
  id: string;
  storeid: string;
};

interface IItemDTO {
  id: string;
  typecast: string;
  name: string;
  description: string;
  content: null;
  image: string;
  ordernation: number;
  externalCode: string;
  price: string;
  productId: string;
  minimum: number;
  calculation: string;
  status: string;
  primaryLevel: string;
  img: null;
  complementPagination: string;
  parentId: string;

  amount?: number;
  complements?: IItemDTO[];
}

const ViewNavFooter: React.FC = () => {
  const hasAdditionals: boolean = useSelector((state: any) => state.hasAdditionals);
  const history = useHistory();
  const productsCart: IItemDTO[] = useSelector((state: any) => state.data);
  const total: number = useSelector((state: any) => state.total);
  // const userAuth: IUserLoginDTO = useSelector((state: any) => state.auth);
  const params = useParams<IRouteParams>();
  return (
    <>
      {hasAdditionals === true ? null : (
        <ContainerButton
          style={{
            display: productsCart.length > 0 && window.location.pathname.split('/')[2] !== 'cart' ? 'flex' : 'none',
          }}
        >
          <Button
            onClick={() => history.push(`/${params.storeid}/resume`)}
            variant="contained"
            style={{ width: '100vw' }}
            color="primary"
          >
            <FaShoppingBag />
            <div>Ver sacola</div>
            <div>{cash(total)}</div>
          </Button>
        </ContainerButton>
      )}
    </>
  );
};

export default ViewNavFooter;
