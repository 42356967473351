import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const Container = styled.div`
  width: 100vw;

  padding-bottom: 60px;
`;

export const Header = styled.div`
  width: 100vw;
  height: 30vh;
`;

export const ImgContainer = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;
  background-color: '#000000';

  img {
    margin-top: 290px;
    height: auto;
    width: 100%;
    z-index: -1;
    position: absolute;
  }
`;

export const TextCategory = styled.div`
  width: 100%;
  color: #ffffff;
  background-color: #181818;
  border: 0px;
  padding: 15px 0;
  text-align: center;
  font-size: 30px;
`;

export const BackgroundColorImg = styled.div`
  z-index: -2;
  width: 100%;
  border: 0px;
  height: 100vh;
  position: absolute;
  background-color: #181818;
`;

export const Wave = styled.img`
  left: 0;
  z-index: -1;
  margin-top: -115px;
  position: absolute;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-left: 25px;
  flex-direction: column;
  text-align: left;
`;

export const TitleText = styled.span`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const SubTitleText = styled.span`
  color: #b2b2b2;
  font-size: 12px;
`;

export const AdditionalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 15px 30px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin: 10px 10px 0px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  div {
    margin: 0;
    text-align: left;

    button {
      width: 0px !important;
      height: 0px !important;
    }

    h5 {
      margin-left: 7px;
      margin-right: 7px;
      font-size: 1.25rem;
    }
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  height: 30px;
  width: 100%;
  z-index: 401;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 0px 10px black);
  box-shadow: 0px 0px 30px black;
  text-align: center;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '90vw',
      marginTop: '20px',
      marginBottom: '27px',
      height: '25vh',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 15,
      backgroundColor: '#f2eded',
      borderStyle: 'solid',
      borderSpacing: '2px',
    },
    button: {
      height: '50px !important',
      color: '#ffffff !important',
      background: '#212121 !important',
      width: '300px !important',
      display: 'flex',
      justifyContent: 'space-between',
      paddingInline: 25,
      fontSize: 16,
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
    imageLoading: {
      marginTop: '0px',
      width: '34px',
      height: '34px',
    },
    fixedBottomBtn: {
      position: 'relative',
      marginTop: '37px',
      bottom: theme.spacing(1) * 2,
    },
  })
);

// export const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//     },
//     menuButton: {
//       marginRight: theme.spacing(2),
//     },
//     title: {
//       flexGrow: 1,
//     },
//   })
// );

export const HeaderNavbar = styled.div`
  background-color: #181818;
  border: none;
  height: 50px;
  padding: 0px 16px;
  display: flex;
  img {
    height: 40px;
    margin-top: 5px;
  }
`;

export const NavContainer = styled.div`
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f7f7f7;
  width: 100%;
  span {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #2d2d2d;
    svg {
      height: 1.5em;
      width: 1.5em;
    }
  }
`;

export const ContainerButton = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 6;
  width: 100%;
  .MuiButton-containedPrimary {
    background-color: #181818 !important;
    /* background-color: var(--ion-color-primary, #3880ff) !important; */
    height: 50px !important;
    width: 100% !important;
    border-radius: 0;
  }
  button {
    span {
      display: flex;
      justify-content: space-between;
    }
  }
`;
