import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useSelector, useDispatch } from 'react-redux';
import { FaCartPlus } from 'react-icons/fa';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory, useParams } from 'react-router-dom';
import Notification from '../../Components/Notification';
import cash from '../../util/cash';
import {
  useStyles,
  Container,
  CardButton,
  CounterContainer,
  InformationsContainer,
  NameItem,
  HeaderCart,
  ComplementsText,
  ItemsComplementsContainer,
  ContainerButton,
} from './styles';

type IRouteParams = {
  id: string;
  storeid: string;
};
interface IItemDTO {
  items: any;
  id: string;
  typecast: string;
  name: string;
  description: string;
  content: null;
  image: string;
  ordernation: number;
  externalCode: string;
  price: string;
  productId: string;
  minimum: number;
  calculation: string;
  status: string;
  primaryLevel: string;
  img: null;
  complementPagination: string;
  parentId: string;
  productType?: any;
  amount?: number;
  complements?: IItemDTO[];
}
const OrderResume: React.FC = () => {
  const total: number = useSelector((state: any) => state.total);
  const history = useHistory();
  const classes = useStyles();
  const [itemValue, setItemValue] = useState(30);
  const [count, setCount] = useState<number[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);

  const [showDeleteItem, setShowDeleteItem] = useState<boolean>(false);
  const productsCart: IItemDTO[] = useSelector((state: any) => state.data);
  const [products, setProducts] = useState<IItemDTO[]>([]);

  const dispatch = useDispatch();

  const params = useParams<IRouteParams>();
  const [isEmpty, setIsEmpty] = useState(true);

  function handleDeleteProduct(value: boolean, product: IItemDTO, index: number) {
    if (!value) {
      handleCloseDeleteProduct(value, product, index);
      return;
    }

    dispatch({ type: 'DELETE_PRODUCT', product: index });
    dispatch({ type: 'ADD_TOTAL', total: subTotal });
  }

  const handleIncrement = (product: IItemDTO, index: number) => {
    const counter = [...count];
    counter[index] += 1;
    setCount(counter);

    setItemValue(itemValue + fixedItemValue);

    setSubTotal(subTotal + Number(product.price));
    dispatch({ type: 'ADD_TOTAL', total: subTotal + Number(product.price) });

    product.amount = count[index] + 1;
    dispatch({ type: 'UPDATE_AMOUNT_PRODUCT', product });
  };

  const fixedItemValue = 30;

  const handleDecrement = (product: IItemDTO, index: number) => {
    if (count[index] === 0) {
      const counter = [...count];
      counter[index] = 0;
      setCount(counter);
    } else {
      const counter = [...count];
      counter[index] -= 1;
      setCount(counter);
      setItemValue(itemValue - fixedItemValue);

      setSubTotal(subTotal - Number(product.price));
    }

    if (count[index] === 1) {
      setShowDeleteItem(true);
    }

    product.amount = count[index] - 1;

    if (product.amount > 0) {
      dispatch({ type: 'UPDATE_AMOUNT_PRODUCT', product });
      dispatch({ type: 'ADD_TOTAL', total: subTotal - Number(product.price) });
    }
  };
  function handleCloseDeleteProduct(value: any, product: IItemDTO, index: number) {
    setShowDeleteItem(value);
    handleIncrement(product, index);
  }
  function viewDeleteProduct(product: IItemDTO, index: number) {
    if (product.amount !== 0) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return (
      <>
        <Notification
          text="Deseja remover esse item do carrinho?"
          onOpen={showDeleteItem}
          onClose={(value: any) => handleDeleteProduct(value, product, index)}
          onConfirm={(value: any) => handleDeleteProduct(value, product, index)}
        />
      </>
    );
  }

  useEffect(() => {
    let value = 0;
    const counter: number[] = [];
    productsCart.forEach((product) => {
      const { price, amount, productType } = product;

      if (productType === 'MONTAGEM') {
        if (amount && amount > 1) {
          counter.push(amount);
          value += Number(price) * amount;
          // @ts-ignore
        } else if (Number.isNaN(amount) || amount === 1) {
          counter.push(1);
          value += Number(price);
        } else {
          counter.push(0);
        }
      }
      if (productType !== 'MONTAGEM') {
        if (amount && amount > 1) {
          counter.push(amount);
          value += Number(price) * amount;
          // @ts-ignore
        } else if (Number.isNaN(amount) || amount === 1) {
          counter.push(1);
          value += Number(price);
        } else {
          counter.push(0);
        }
      }
    });
    if (counter.length === 0) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
    setCount(counter);
    setSubTotal(value);
    setProducts(productsCart);
  }, [productsCart]);

  // useEffect(() => {
  //   let value = 0;
  //   const counter: number[] = [];
  //   productsCart.forEach((product) => {
  //     const { price, amount, productType } = product;

  //     if (productType === 'MONTAGEM') {
  //       if (amount && amount > 1) {
  //         counter.push(amount);
  //         value += Number(price) * amount;
  //         // @ts-ignore
  //       } else if (Number.isNaN(amount) || amount === 1) {
  //         counter.push(1);
  //         value += Number(price);
  //       } else {
  //         counter.push(0);
  //       }
  //     } else if (amount && amount > 1) {
  //       counter.push(amount);
  //       value += Number(price) * amount;
  //       // @ts-ignore
  //     } else if (Number.isNaN(amount) || amount === 1) {
  //       counter.push(1);
  //       value += Number(price);
  //     } else {
  //       counter.push(0);
  //     }
  //   });
  //   if (counter.length === 0) {
  //     setIsEmpty(false);
  //   } else {
  //     setIsEmpty(true);
  //   }
  //   setCount(counter);
  //   setSubTotal(value);
  //   setProducts(productsCart);
  // }, [productsCart]);
  return (
    <div>
      <Container>
        <HeaderCart>
          <BorderColorIcon style={{ fontSize: 65, marginTop: 20 }} />
        </HeaderCart>
        {products.map((product: IItemDTO, index: number) => (
          <>
            {viewDeleteProduct(product, index)}
            {/* {product.productType === 'MONTAGEM' ? (
              <>
                <span>product.name</span>
              </>
            ) : null} */}
            <CardButton>
              {product.productType === 'MONTAGEM' ? (
                <ItemsComplementsContainer>
                  <NameItem>{product.name}</NameItem>
                  {product.complements?.map((complement: IItemDTO) =>
                    complement.items.map((item: IItemDTO) => (
                      <>
                        <ComplementsText>{item.name}</ComplementsText>
                      </>
                    ))
                  )}
                </ItemsComplementsContainer>
              ) : (
                <ItemsComplementsContainer>
                  <NameItem>{product.name}</NameItem>
                  {product.complements?.map((complement: IItemDTO) => (
                    <>
                      <ComplementsText>{complement.name}</ComplementsText>
                    </>
                  ))}
                </ItemsComplementsContainer>
              )}
              <InformationsContainer>
                <span>{cash(product.price)}</span>
                <CounterContainer>
                  <IconButton className={classes.ButtonCart} onClick={() => handleIncrement(product, index)}>
                    <AddCircleIcon />
                  </IconButton>
                  <Typography className={classes.count} variant="h5">
                    {count[index]}
                  </Typography>
                  <IconButton className={classes.ButtonCart} onClick={() => handleDecrement(product, index)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </CounterContainer>
              </InformationsContainer>
            </CardButton>
          </>
        ))}
        {isEmpty && (
          <ContainerButton>
            <Button
              variant="contained"
              style={{ width: '100vw' }}
              color="primary"
              onClick={() => history.push(`/${params.storeid}/auth`)}
            >
              <FaCartPlus />
              <div style={{ marginLeft: 50 }}>Finalizar</div>
              <div>{cash(total)}</div>
            </Button>
          </ContainerButton>
        )}
      </Container>
    </div>
  );
};
export default OrderResume;
