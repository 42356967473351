/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import {
  AdditionalContainer,
  SubTitleText,
  Container,
  ButtonsCountContainer,
  useStyles,
  SubTitleDescription,
} from './styles';
import 'moment-timezone';
import 'moment/locale/pt';
// eslint-disable-next-line import/order
import { Grid, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import cash from '../../util/cash';

interface IPropDTO {
  // eslint-disable-next-line react/require-default-props
  data: any;
  product: any;
  onCount: any;
  complementsData: any;
}

const FormOrders = ({ data, product, onCount, complementsData }: IPropDTO) => {
  const classes = useStyles();
  // const [count, setCount] = React.useState(0);
  // const [selectedOptionMenu, setSelectedOptionMenu] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [complementData, setComplementData] = React.useState<any>();
  const [productData, setProductData] = React.useState<any>();

  const handleProductsData = () => {
    setProductData(data);
  };

  useEffect(() => {
    handleProductsData();
  }, [data]);

  const handleCountAmountProduct = async (productsTotal: any): Promise<void> => {
    // eslint-disable-next-line prefer-const
    let total = 0;
    let valueTotal = 0;

    productsTotal.forEach((items: any) => {
      items[0].complements.forEach((i: any) => {
        if (i.selected) {
          total += Number(i.price) * i.selected;
        }
      });
    });
    valueTotal = total + Number(product.price);
    onCount(valueTotal);
  };

  // Função para regra de selecionar um unico complemento
  const handleSelectedOneComplement = async (complement: any, productComplements: any) => {
    // Verifica se o complemento está selecionado
    if (complement.selected === undefined) {
      complement.selected = true;
    } else {
      complement.selected = !complement.selected;
    }

    // Verifica quantos complementos estão selecionados
    let countQuantitySelected = 0;
    productComplements.complements.forEach((complementSelected: any) => {
      if (complementSelected.selected) {
        countQuantitySelected += complementSelected.selected;
      }
    });
    // Se o complemento estiver selecionado, verifica se o produto possui máximo de seleção
    // Caso possua, muda a seleção para o atual e desmarca os outros
    if (productComplements.product.maximum < countQuantitySelected) {
      productComplements.complements.forEach((complements: any) => {
        if (complements.id === complement.id) {
          complement.selected = true;
        } else if (complements.selected !== undefined) {
          complements.selected = false;
        }
      });
    }

    handleCountAmountProduct(data);
    // Atualiza todos os dados do produto e complementos
    const items = Array.from(data);
    complementsData(items);
    setComplementData(items);
  };

  function handleComplementRadioButton(complement: any, complementId: string, productComplement: any) {
    return (
      <FormControlLabel
        value={complementId}
        checked={!!complement.selected}
        control={<Radio />}
        label=""
        color="default"
        // label={complement.name}
        labelPlacement="start"
        onClick={() => handleSelectedOneComplement(complement, productComplement)}
      />
    );
  }

  const handleSelectedMultipleComplement = (
    complement: any,
    productComplement: any,
    index: number,
    typeSelected: any
  ) => {
    // Verifica quantos complementos estão selecionados
    let countQuantitySelected = 0;
    productComplement.complements.forEach((complementSelected: any) => {
      if (complementSelected.selected) {
        countQuantitySelected += complementSelected.selected;
      }
    });

    if (countQuantitySelected >= productComplement.product.maximum && typeSelected === 'Increment') {
      return;
    }

    // Verifica se a quantidade foi Incremento
    if (typeSelected === 'Increment') {
      if (complement.selected === undefined) {
        complement.selected = 1;
      } else {
        complement.selected += 1;
      }
    }
    // Verifica se a quantidade foi Decremento
    if (typeSelected === 'Decrement') {
      if (complement.selected === undefined) {
        return;
      }
      if (complement.selected > 0) {
        const selected = complement.selected - 1;
        complement.selected = selected;
      }
    }
    handleCountAmountProduct(data);
    // Atualiza todos os dados do produto e complementos
    const items = Array.from(data);
    complementsData(items);
    setComplementData(items);
  };

  function handleComplementGroupedButtons(complement: any, productComplement: any, indexComplement: any) {
    return (
      <div style={{ paddingTop: '10px' }}>
        <span style={{ display: 'flex' }}>
          <ButtonsCountContainer>
            <>
              <IconButton
                className={classes.Button}
                onClick={() =>
                  handleSelectedMultipleComplement(complement, productComplement, indexComplement, 'Decrement')
                }
              >
                -
              </IconButton>
              <Typography className={classes.count} variant="h5">
                {complement.selected ? complement.selected : 0}
              </Typography>
            </>
            <IconButton
              className={classes.Button}
              onClick={() =>
                handleSelectedMultipleComplement(complement, productComplement, indexComplement, 'Increment')
              }
            >
              +
            </IconButton>
          </ButtonsCountContainer>
        </span>
      </div>
    );
  }

  return (
    <Container>
      <Grid container direction="column" alignItems="center" justify="center">
        <AdditionalContainer>
          {productData?.map((items: any) =>
            items?.map((item: any) => (
              <>
                <div style={{ justifyContent: 'column' }}>
                  <div style={{ justifyContent: 'row' }}>
                    <div
                      key={item.id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#f2f2f2',
                        height: '60px',
                      }}
                    >
                      <div style={{ width: '100%', paddingTop: '10px' }}>
                        <div style={{ marginLeft: '15px' }}>{item.product.name}</div>
                        {item.product.maximum > 1 ? (
                          <SubTitleText>Escolha até {item.product.maximum} opções.</SubTitleText>
                        ) : (
                          <SubTitleText>Escolha {item.product.maximum} opção.</SubTitleText>
                        )}
                      </div>
                    </div>
                    <div style={{ backgroundColor: '#ffff' }}>
                      {item.complements.map((complement: any, indexComplement: number) => (
                        <div
                          style={{
                            display: 'flex',
                            height: 'auto',
                            paddingTop: '10px',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #f2f2f2',
                          }}
                        >
                          <div style={{ width: '70%', paddingTop: '5px', marginLeft: '15px' }}>
                            <p>{complement.name}</p>
                            <SubTitleDescription>{complement.description}</SubTitleDescription>
                            <div style={{ marginTop: '3px' }}>
                              <span> + {cash(complement.price)}</span>
                            </div>
                            {/* {complement.price > 0 ? (
                              <div style={{ marginTop: '3px' }}>
                                <span> + {cash(complement.price)}</span>
                              </div>
                            ) : null} */}
                          </div>
                          <div style={{ marginRight: '15px' }}>
                            {item.product.maximum > 1
                              ? handleComplementGroupedButtons(complement, item, indexComplement)
                              : handleComplementRadioButton(complement, complement.id, item)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ))
          )}
        </AdditionalContainer>
      </Grid>
    </Container>
  );
};

export default FormOrders;
