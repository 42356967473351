/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Grid, Paper, Button, TextField, CssBaseline } from '@material-ui/core';

import { v4 as uuidv4 } from 'uuid';
import { IRouteParams } from '../../interfaces/IRouteParamsDTO';

import OrderDetails from '../../Components/CheckoutOrderDetails';
import NotificationOrderResumeOrMenu from '../../Components/NotificationOrderResumeOrMenu';
import Counter from '../../Components/Counter';
import cash from '../../util/cash';

import {
  Container,
  TextContainer,
  TitleText,
  ImgContainer,
  useStyles,
  BackgroundColorImg,
  AdditionalContainer,
  ContainerButton,
} from './styles';

const CheckoutOrderDetails: React.FC = () => {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const dispatch: any = useDispatch();
  const [isDelivery] = useState(false);
  // const [itemData, setItemData] = useState<IItemData>({} as IItemData);
  const totalProductCheckout: any = useSelector((state: any) => state.totalProductCheckout);
  const checkoutOrderDetails: [] = useSelector((state: any) => state.checkoutOrderDetails);
  const itemData = useSelector((state: any) => state.itemData);
  const [showDeleteItem, setShowDeleteItem] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [toastAddingItemToCart, setToastAddingItemToCart] = useState(false);
  const totalCart: number = useSelector((state: any) => state.total);

  useEffect(() => {
    // @ts-ignore
    setTotal(totalProductCheckout);
    // for (const [, v] of checkoutOrderDetails.entries()) {
    //   if(v.total){
    //     setTotal(v.total)
    //   }
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  function handleItemAmount(value: number) {
    if (value <= 0) {
      return;
    }
    const currAmount = value;
    const prevAmount = amount;
    const price = total;

    if (currAmount > prevAmount) {
      setTotal((price / amount) * value);
    } else if (prevAmount > currAmount) {
      setTotal((price / amount) * value);
    } else {
      setTotal(price);
    }

    setAmount(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleConfirmBuyAndResumeOrder () {
    const vid = uuidv4();
    const totalPrice = total;
    const productValue = total / amount;
    // @ts-ignore
    const product = { name: itemData.name, id: itemData.id, price: productValue, typecast: 'P', vid, totalPrice, amount, productType: 'MONTAGEM', complements: [... checkoutOrderDetails]}
    dispatch({ type: 'ADD_TOTAL', total: totalCart + total });
    dispatch({ type: 'ADD_PRODUCT', product });
    dispatch({ type: 'ADD_ASSEMBLY_PRODUCT', assemblyProduct: [] });
    history.push(`/${params.storeid}/resume`)
  }

  function handleBackForHome () {
    const vid = uuidv4();
    const totalPrice = total;
    const productValue = total / amount;
    // @ts-ignore
    const product = { name: itemData.name, id: itemData.id, price: productValue, typecast: 'P', vid, totalPrice, amount, productType: 'MONTAGEM', complements: [... checkoutOrderDetails]}
    dispatch({ type: 'ADD_TOTAL', total: totalCart + total });
    dispatch({ type: 'ADD_PRODUCT', product });
    dispatch({ type: 'ADD_ASSEMBLY_PRODUCT', assemblyProduct: [] });
    dispatch({ type: 'PRODUCT_TYPE', productType: 'SIMPLES' });
    history.push(`/${params.storeid}`)
  }

  const handleOptionsOrderDateil = () => {
    setShowDeleteItem(!showDeleteItem);
  }

  const delayTimer = 1.5;
  useEffect(() => {
    let timer = setTimeout(() => setToastAddingItemToCart(false), delayTimer * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [toastAddingItemToCart]);

  const classes = useStyles();
  return (
    <>
      <Container>
        <CssBaseline />
        {!itemData?.image && (
          <>
            <ImgContainer>
              <Loader
                style={{
                  paddingTop: '60px',
                  paddingBottom: '60px',
                  paddingLeft: '145px',
                  paddingRight: '145px',
                  backgroundColor: '#181818',
                }}
                type="RevolvingDot"
                color="#FFFF"
                height={100}
                width={100}
              />
            </ImgContainer>
          </>
        )}

        {itemData?.image && (
          <>
            <ImgContainer key={itemData.id}>
              {/* @ts-ignore */}
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <img className={classes.image} src={itemData?.image} alt="Produto" />
                <BackgroundColorImg />
              </Grid>
            </ImgContainer>
          </>
        )}
        <Grid container justify="center">
          <TextContainer>
            <TitleText>{itemData?.name}</TitleText>
          </TextContainer>
          <Paper variant="outlined" elevation={1} className={classes.paper}>
            <Grid container direction="column" alignItems="center" justify="center">
              <AdditionalContainer>
                <OrderDetails OrderResume={checkoutOrderDetails} />
              </AdditionalContainer>
              <TextField
                style={{
                  width: 'calc(100% - 20px);',
                  marginTop: '15px',
                  borderStyle: 'solid',
                  borderColor: '#212121',
                  backgroundColor: '#fff',
                  display: isDelivery ? 'flex' : 'none',
                }}
                multiline
                rows={1}
                placeholder="Alguma Observação ?"
                defaultValue=""
                variant="outlined"
              />
            </Grid>
          </Paper>
          <span style={{ display: isDelivery ? 'flex' : 'flex' }}>
              <Counter countAmount={amount} onCount={(value: any) => handleItemAmount(value)} />
          </span>
        </Grid>
        <Grid
          style={{ display: isDelivery ? 'flex' : 'none' }}
          className={classes.fixedBottomBtn}
          container
          justify="center"
          />
      </Container>
        <ContainerButton>
          <Button onClick={handleOptionsOrderDateil} variant="contained" style={{ width: '100vw' }} color="primary">
            <div />
            <div>ADICIONAR AO CARRINHO</div>
            <div>{cash(total)}</div>
          </Button>
          <>
        <NotificationOrderResumeOrMenu
          text="O que deseja fazer agora?"
          onOpen={showDeleteItem}
          onClose={handleBackForHome}
          onConfirm={handleConfirmBuyAndResumeOrder}
        />
      </>
        </ContainerButton>
    </>
  );
};

export default CheckoutOrderDetails;
