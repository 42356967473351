/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState, Dispatch, SetStateAction, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
// import { useHistory, useParams } from 'react-router-dom';

import {
  ButtonConfirm,
  ButtonCancel,
  NotificationContainer,
  ButtonsContainer,
  TextNotificationContainer,
  TextNotification,
} from './styles';

interface IPropsDTO {
  text?: string;
  children?: any;
  onOpen?: boolean;
  onConfirm: Dispatch<SetStateAction<boolean>>;
  onClose: Dispatch<SetStateAction<boolean>>;
}

// type IRouteParams = {
//   orderId: string;
//   storeid: string;
// };

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
    height: 250,
  },
});

const NotificationOrderResumeOrMenu = ({ text, onOpen = false, onClose, onConfirm, children }: IPropsDTO) => {
  const classes = useStyles();
  // const history = useHistory();
  // const params = useParams<IRouteParams>();

  const [openNotification, setOpenNotification] = useState<boolean>(onOpen);

  useEffect(() => {
    setOpenNotification(onOpen);
  }, [onOpen]);

  function handleConfirmation() {
    onConfirm(true);
    // onClose(false);
  }

  function handleGoHome() {
    onClose(true);
  }

  const anchor = 'bottom';

  const typeViewElement = () => {
    if (!children) {
      return (
        <>
          {/* <FaExclamationCircle /> */}
          <TextNotification>{text}</TextNotification>
        </>
      );
    }

    return <>{children}</>;
  };

  const list = () => (
    <NotificationContainer
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'bottom',
      })}
      style={{ position: 'relative', height: '100%' }}
      role="presentation"
    >
      <div>
        <TextNotificationContainer>{typeViewElement()}</TextNotificationContainer>

        <ButtonsContainer>
          <ButtonCancel>
            <Button onClick={() => handleGoHome()}>Continuar comprando</Button>
          </ButtonCancel>
          <ButtonConfirm>
            <Button onClick={() => handleConfirmation()}>Concluir pedido</Button>
          </ButtonConfirm>
        </ButtonsContainer>
      </div>
    </NotificationContainer>
  );

  return (
    <NotificationContainer>
      <Fragment key={anchor}>
        <Drawer anchor={anchor} open={openNotification}>
          {list()}
        </Drawer>
      </Fragment>
    </NotificationContainer>
  );
};

export default NotificationOrderResumeOrMenu;
