import styled from 'styled-components';

export const CardButton = styled.div`
  width: '100vw';
  max-width: '100vw';
  background-color: #f7f7f7;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  margin: 10px;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: #000000;
    width: 100%;
  }

  ul {
    li {
      div {
        margin-right: -10px;
        margin-left: -3px;
      }
    }
  }

  li {
    padding: 0;
    height: 30px;

    p {
      text-overflow: ellipsis;
      width: calc(100% - 0px);
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const NameItem = styled.span`
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  margin-left: 5px;
`;

export const ComplementsText = styled.span`
  font-size: 12px;
  color: #909090;
  text-align: left;
  margin-left: 5px;
`;

export const ItemsComplementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #aaa;
`;
