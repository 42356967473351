import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const Container = styled.div``;

export const AdditionalContainer = styled.form`
  display: block;
  justify-content: space-between;
  width: 100%;
  /* padding: 15px 30px; */
  /* background-color: #f2f2f2; */
  /* border-radius: 5px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  margin: 0px;

  div {
    margin: 0;
    text-align: left;

    button {
      width: 0px !important;
      height: 0px !important;
    }

    h5 {
      margin-left: 7px;
      margin-right: 7px;
      font-size: 1.25rem;
    }
  }
`;
export const SubTitleDescription = styled.span`
  color: #b2b2b2;
  font-size: 12px;
`;

export const SubTitleText = styled.span`
  margin-left: 15px;
  color: #b2b2b2;
  font-size: 12px;
`;

export const ButtonsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    margin-left: 21px;
    margin-right: 21px;
  }

  button {
    color: #000000 !important;
    height: 30px !important;
    width: 30px !important;
    /* border-radius: 100px !important; */
    /* border: 0.5px solid #181818 !important; */
    /* box-shadow: 0px 1.5px 0px #181818 !important; */
    /* background-color: transparent !important; */
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    count: {
      margin: '0 10px 0 10px',
    },
    Grid: {
      marginTop: '30px',
      marginBottom: '20px',
    },
    Button: {
      height: '50px !important',
      width: '50px !important',
      borderRadius: '50%',
    },
    Icon: {
      height: 'auto !important',
      marginLeft: '8px',
      background: '#212121 !important',
      width: 'auto !important',
    },
    GridCart: {
      marginTop: '30px',
      width: '30vw',
      marginLeft: theme.spacing(9) * 2,
    },
    ButtonCart: {
      height: '20px !important',
      width: '20px !important',
      borderRadius: '50%',
    },
  })
);
