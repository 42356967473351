import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiDrawer-paperAnchorBottom {
    height: 400px !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button#btn-cancel-notification {
    position: absolute;
    left: 30px;
    bottom: 0;
    color: #ffff;
    height: 50px !important;
    background-color: black !important;
  }

  button#btn-confirm-notification {
    position: absolute;
    right: 30px;
    bottom: 0;
    color: #ffff;
    height: 50px !important;
    background-color: black !important;
  }
`;

export const TextNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  align-items: center;
`;

export const TextNotification = styled.span`
  font-size: 18px;
`;
