import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  body {
    color: #000000;
    background-color: #f7f7f7;
    font-family: 'Roboto', sans-serif;
  }

  #root {
    height: 100%;
  }

  /*
    ***** NÃO APAGAR *****
  button {
    height: 50px !important;
    color: #ffffff !important;
    background: #212121 !important;
    width: calc(100% - 15px) !important;
  }

  .MuiIconButton-sizeSmall {
    height: 25px !important;
    width: 25px !important;
  } */

  textarea {
    height: 30px;
    margin-top: -17px;
    margin-bottom: -17px;
    padding: 10px 0px;
  }

  #mapid {
    width: 100%;
    height: 300px;
  }

  .MuiFormControl-root {
    width: calc(100% - 15px);
  }

  .MuiDrawer-paperAnchorBottom {
    display: flex;
    height: 430px;
    padding: 15px;
    align-content: center;
    text-align: center;
    svg {
      margin-bottom: 50px;
      font-size: 50px;
    }
    button {
      padding-inline: 30px;
      width: auto !important;
    }
  }

  .MuiPaper-root {
    padding-bottom: 10px;
  }

  .MuiPaper-outlined {
    height: 100% !important;
  }

  li {
    p {
      text-overflow: ellipsis;
      width: calc(100% - 5px);
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
