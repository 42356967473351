/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-debugger */
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Grid, Button, TextField, CssBaseline } from '@material-ui/core';

import { v4 as uuidv4 } from 'uuid';
import { IItemData } from '../../interfaces/IItemDataDTO';
import { IRouteParams } from '../../interfaces/IRouteParamsDTO';
import { IProdMode } from '../../interfaces/IProdMode';

import Counter from '../../Components/Counter';
// import CounterPizza from '../../Components/CounterPizza';
import FormOrders from '../../Components/FormOrders';

import cash from '../../util/cash';
import api from '../../service/api';
import Toast from '../../Components/Toast';
import {
  // Wave,
  Container,
  TextContainer,
  TitleText,
  SubTitleText,
  ImgContainer,
  useStyles,
  BackgroundColorImg,
  DishContentAction,
  DishContentAction1,
  DishContentActionBtn,
  DishContentActionBtn1,
  DishContentAction2,
  // DishButton,
  // AdditionalContainer,
} from './styles';

import ModalStoreStatus from '../../Components/ModalStoreStatus';

const Item: React.FC = () => {
  const history = useHistory();

  const params = useParams<IRouteParams>();
  const dispatch: any = useDispatch();
  const [isDelivery, setIsDelivery] = useState(false);
  const [itemData, setItemData] = useState<IItemData>({} as IItemData);
  const [additionals, setAdditionals] = useState<IItemData[]>([]);

  // Verificar o tamanho da imagem para ajustar o tamanho do background
  const [verifyImageSize, setVerifyImageSize] = useState(false);
  const [amount, setAmount] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [complementAmount, setComplementAmount] = useState<number[]>([]);
  const [hasDifferentProdMode, setHasDifferentProdMode] = useState<IProdMode>({
    hasCalc: false,
    mode: '',
  });
  const [toastAddingItemToCart, setToastAddingItemToCart] = useState(false);

  const totalCart: number = useSelector((state: any) => state.total);
  const hasAdditionals: boolean = useSelector((state: any) => state.hasAdditionals);
  const [complementData, setComplementData] = React.useState<any>();
  const [storeStatus, setStoreStatus] = useState(false);

  const handleFatherProduct = useCallback(async () => {
    await api.get(`/products/item/${params.id}`).then((response) => {
      const result: IItemData = response.data;
      setItemData(result);

      const img = new Image();
      img.src = result.image;
      img.onload = () => {
        if (img.width < 200 || img.height < 200) {
          setVerifyImageSize(true);
        }
      };

      if (result.prodMode && result.prodMode !== 'n') {
        setHasDifferentProdMode({ hasCalc: true, mode: result.prodMode });
      }
      setTotal(Number(result.price));
    });
  }, [params.id]);

  useEffect(() => {
    api.get(`/stores/friendlylink/${params.storeid}`).then((response) => {
      const { data } = response;
      setIsDelivery(data.deliveryActive);
    });
  });

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    handleFatherProduct();
  }, [handleFatherProduct]);

  // Função atual

  useEffect(() => {
    let items: IItemData[] = [];
    async function handleItem(): Promise<void> {
      async function handleAdditional(itemsId: string): Promise<void> {
        if (itemsId === '0') {
          setAdditionals([]);
          return;
        }
        for (const item of itemsId) {
          // @ts-ignore
          const complements = await api.get(`/products/${params.storeid}/primary/items/${item.id}`);
          const itemsAndComplements = [{ product: item, complements: complements.data }];
          // @ts-ignore
          items.push(itemsAndComplements);
        }
        // @ts-ignore
        if (!itemsId.length) {
          dispatch({ type: 'HAS_ADDITIONALS', hasAdditionals: false });
        } else {
          dispatch({ type: 'HAS_ADDITIONALS', hasAdditionals: true });
        }
        setAdditionals(items);
      }

      await api.get(`/products/${params.storeid}/primary/items/${params.id}`).then((response) => {
        const result = response.data as IItemData;
        // @ts-ignore
        handleAdditional(!result ? '0' : result);
      });
    }

    handleItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.storeid]);

  // Função anterior

  // useEffect(() => {
  //   async function handleItem(): Promise<void> {
  //     async function handleAdditional(itemId: string): Promise<void> {
  //       if (itemId === '0') {
  //         setAdditionals([]);
  //         return;
  //       }

  //       await api.get(`/products/${params.storeid}/primary/items/${itemId}`).then((response) => {
  //         const items: IItemData[] = response.data;
  //         for (let i = 0; i < response.data.length; i++) {
  //           items[i].parentId = itemId;
  //         }
  //         setAdditionals(items);
  //       });
  //     }

  //     await api.get(`/products/${params.storeid}/primary/items/${params.id}`).then((response) => {
  //       const result = response.data[0] as IItemData;
  //       handleAdditional(!result ? '0' : result.id);
  //     });
  //   }

  //   handleItem();
  // }, [params.id, params.storeid]);

  useEffect(() => {
    const count: number[] = [];
    additionals.forEach(() => {
      count.push(0);
    });

    setComplementAmount(count);
  }, [additionals]);

  // const verifyIsPizza = (item: IItemData) => {
  //   if (item.prodMode === 'S' || item.prodMode === 'M') {
  //     return true;
  //   }
  //   return false;
  // };

  const verifyStoreStatus = async () => {
    const status = await api.get(`/stores/friendlylink/${params.storeid}`).then((response) => {
      const { data } = response;
      data.action === 'open'
        ? dispatch({ type: 'CLOSED_STORE', store: false })
        : dispatch({ type: 'CLOSED_STORE', store: true });
      return data.action;
    });
    return status;
  };

  const addProductToCart = async (product: IItemData) => {
    const status = await verifyStoreStatus();
    if (status !== 'open') {
      setStoreStatus(true);
      return;
    }

    product.amount = amount;
    product.vid = uuidv4();
    // product.price = String(total);

    const onlyComplements = additionals.filter((additional: any, index: number) => complementAmount[index] !== 0);
    const onlyComplementsAmount = complementAmount.filter(
      (complementAmountValue: number) => complementAmountValue !== 0
    );

    product.complements = onlyComplements;

    for (let i = 0; i < product.complements.length; i++) {
      product.complements[i].amount = onlyComplementsAmount[i];
    }

    let valueTotal;
    if (itemData.prodMode === 'N') {
      if (amount > 1) {
        valueTotal = total / amount;
        product.price = valueTotal.toString();
      } else {
        product.price = total.toString();
      }
    } else if (itemData.prodMode === 'M' || itemData.prodMode === 'S') {
      valueTotal = total / amount;
      product.price = valueTotal.toString();
    }

    product.totalPrice = total;

    dispatch({ type: 'ADD_PRODUCT', product });

    dispatch({ type: 'ADD_TOTAL', total: totalCart + total });
    handleAddingProduct();
    setToastAddingItemToCart(true);
  };

  const addProductToCartTeste = () => {
    const vid = uuidv4();
    let ordersDetails: any[] = [];
    if (complementData) {
      // return;
      complementData.forEach((items: any, index: number) => {
        items[0].complements.forEach((item: any) => {
          if (item.selected) {
            if (!ordersDetails[index]) {
              ordersDetails.push({
                id: items[0].product.id,
                name: items[0].product.name,
                items: [item],
              });
            } else {
              ordersDetails[index].items.push(item);
            }
          }
        });
      });
    }
    const productValue = total / amount;
    const product = {
      name: itemData.name,
      id: itemData.id,
      price: productValue,
      typecast: 'P',
      vid,
      total,
      amount,
      productType: 'MONTAGEM',
      complements: [...ordersDetails],
    };
    dispatch({ type: 'ADD_TOTAL', total: totalCart + total });
    dispatch({ type: 'ADD_PRODUCT', product });
    dispatch({ type: 'HAS_ADDITIONALS', hasAdditionals: false });
    history.push(`/${params.storeid}`);
  };

  const handleAddingProduct = useCallback(async () => {
    await api.get(`/products/item/${params.id}`).then((response) => {
      const result: IItemData = response.data;
      setItemData(result);
    });
  }, [params.id]);

  async function handleComplementAmountProdModeDifferent(value: number, index: number) {
    const count = [...complementAmount];

    count[index] = value;
    if (count[index] > 1) {
      count[index] = 1;
      return;
    }
    await setComplementAmount(count);

    const additionalsAux: IItemData[] = [];
    if (additionals.length > 0) {
      count.forEach((cmplAmount: number, i: number) => {
        if (cmplAmount > 0) {
          additionalsAux.push(additionals[i]);
        } else if (value === 1 && index === i) {
          additionalsAux.push(additionals[index]);
        }
      });
    }

    let parcialTotal = 0;
    let valueTotal = 0;
    let priceToFixed: string;

    if (hasDifferentProdMode?.mode === 'M') {
      additionalsAux.forEach((item) => {
        parcialTotal += Number(item.price);
      });

      parcialTotal /= additionalsAux.length;
      priceToFixed = parcialTotal.toFixed(2);
      valueTotal = Number(priceToFixed) * amount;
    } else {
      let superiorPrice = 0;
      additionalsAux.forEach((item) => {
        if (Number(item.price) > superiorPrice) {
          superiorPrice = Number(item.price);
        }
      });
      valueTotal = superiorPrice;
    }

    if (Number.isNaN(valueTotal)) {
      setTotal(0);
    } else {
      setTotal(valueTotal);
    }
  }

  function handleComplementAmount(value: number, index: number) {
    if (hasDifferentProdMode.hasCalc && hasDifferentProdMode.mode !== 'N') {
      handleComplementAmountProdModeDifferent(value, index);
      return;
    }

    const count = [...complementAmount];
    count[index] = value;

    let additionalValue = 0;
    if (additionals.length > 0) {
      complementAmount.forEach((cmplAmount: number, i: number) => {
        if (cmplAmount > 0) {
          if (index === i) {
            additionalValue += Number(additionals[index].price) * value;
          } else {
            additionalValue += Number(additionals[i].price) * cmplAmount;
          }
        } else if (value === 1 && index === i) {
          additionalValue += Number(additionals[index].price);
        }
      });
    } else {
      additionalValue += Number(additionals[index].price) * value;
    }

    const parcialTotal = (Number(itemData.price) + additionalValue) * amount;
    setTotal(parcialTotal);

    setComplementAmount(count);
  }
  function handleItemAmount(value: number) {
    const currAmount = value;
    const prevAmount = amount;
    const price = total;

    if (currAmount > prevAmount) {
      setTotal((price / amount) * value);
    } else if (prevAmount > currAmount) {
      setTotal((price / amount) * value);
    } else {
      setTotal(price);
    }

    setAmount(value);
  }

  function handleItemAmountFormsOrders(value: number) {
    // const currAmount = value;
    // const prevAmount = amount;
    const price = value;

    // if (currAmount > prevAmount) {
    //   setTotal((price / amount) * value);
    // } else if (prevAmount > currAmount) {
    //   setTotal((price / amount) * value);
    // } else {
    // }
    setTotal(price);

    // setAmount(value);
  }

  const delayTimer = 1.5;
  useEffect(() => {
    let timer = setTimeout(() => setToastAddingItemToCart(false), delayTimer * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [toastAddingItemToCart]);

  const classes = useStyles();
  return (
    <>
      <Container>
        <CssBaseline />
        {!itemData?.image && (
          <>
            <ImgContainer>
              <Loader
                style={{
                  paddingTop: '60px',
                  paddingBottom: '60px',
                  paddingLeft: '145px',
                  paddingRight: '145px',
                  backgroundColor: '#181818',
                }}
                type="RevolvingDot"
                color="#FFFF"
                height={100}
                width={100}
              />
            </ImgContainer>
          </>
        )}

        {itemData?.image && (
          <>
            <ImgContainer key={itemData.id}>
              {/* @ts-ignore */}
              <Grid container direction="column" justifyContent="center" alignItems="center">
                {verifyImageSize ? (
                  <>
                    <img
                      style={{ width: '300px', height: '250px' }}
                      className={classes.image}
                      src={itemData?.image}
                      alt="Produto"
                    />
                  </>
                ) : (
                  <>
                    <img className={classes.image} src={itemData?.image} alt="Produto" />
                  </>
                )}
                <BackgroundColorImg />
              </Grid>
            </ImgContainer>
          </>
        )}
        {/* <Wave src="https://svgshare.com/i/Thj.svg" alt="background" /> */}
        <Grid container justify="center">
          <TextContainer>
            <TitleText>{itemData?.name}</TitleText>
            <SubTitleText>{itemData?.description}</SubTitleText>
          </TextContainer>
          {additionals.length > 0 ? (
            <div style={{ width: '100%', marginTop: '20px' }}>
              <FormOrders
                data={additionals}
                product={itemData}
                complementsData={(value: any) => setComplementData(value)}
                onCount={(value: any) => handleItemAmountFormsOrders(value)}
              />
            </div>
          ) : null}
          <TextField
            style={{
              width: '100%',
              height: 'auto',
              marginTop: '15px',
              borderStyle: 'solid',
              borderColor: '#212121',
              backgroundColor: '#fff',
              display: isDelivery ? 'flex' : 'none',
            }}
            multiline
            rows={1}
            placeholder="Alguma Observação ?"
            defaultValue=""
            variant="outlined"
          />
          {hasAdditionals === true ? null : (
            <>
              <span style={{ paddingTop: '20px', display: isDelivery ? 'flex' : 'none' }}>
                <Counter countAmount={amount} onCount={(value: any) => handleItemAmount(value)} />
              </span>
            </>
          )}
          {/* <Paper variant="outlined" elevation={1} className={classes.paper}>
            <Grid container direction="column" alignItems="center" justify="center">
              {additionals.map((a: IItemData, index: number) => (
                <>
                  <AdditionalContainer key={a.id}>
                    <div key={a.id} style={{ display: 'flex', flexDirection: 'column' }}>
                      <div>{a.name}</div>
                      <div>{cash(a.price)}</div>
                    </div>
                    {verifyIsPizza(itemData) ? (
                      <span style={{ display: isDelivery ? 'flex' : 'none' }}>
                        <CounterPizza onCount={(value: any) => handleComplementAmount(value, index)} countAmount={0} />
                      </span>
                    ) : (
                      <span style={{ display: isDelivery ? 'flex' : 'none' }}>
                        <Counter onCount={(value: any) => handleComplementAmount(value, index)} countAmount={0} />
                      </span>
                    )}
                  </AdditionalContainer>
                </>
              ))}
              <TextField
                style={{
                  width: 'calc(100% - 20px);',
                  marginTop: '15px',
                  borderStyle: 'solid',
                  borderColor: '#212121',
                  backgroundColor: '#fff',
                  display: isDelivery ? 'flex' : 'none',
                }}
                multiline
                rows={1}
                placeholder="Alguma Observação ?"
                defaultValue=""
                variant="outlined"
              />
            </Grid>
          </Paper> */}
        </Grid>
        {toastAddingItemToCart === true ? <Toast type="success" message="Item adicionado à sacola" /> : null}
        <Grid
          style={{ display: isDelivery ? 'flex' : 'none' }}
          className={classes.fixedBottomBtn}
          container
          justify="center"
        >
          {storeStatus === true ? (
            <ModalStoreStatus
              onClose={() => {
                setStoreStatus(false);
              }}
            />
          ) : null}

          {hasAdditionals === true ? (
            <DishContentAction>
              <DishContentAction1>
                <DishContentAction2>
                  <DishContentActionBtn1>
                    <Counter countAmount={amount} onCount={(value: any) => handleItemAmount(value)} />
                  </DishContentActionBtn1>
                  <DishContentActionBtn>
                    <button type="button" onClick={addProductToCartTeste}>
                      <div>
                        <span>Adicionar</span>
                        <span>{cash(total)}</span>
                      </div>
                    </button>
                  </DishContentActionBtn>
                </DishContentAction2>
              </DishContentAction1>
            </DishContentAction>
          ) : (
            <Button
              onClick={() => addProductToCart(itemData)}
              variant="contained"
              className={classes.button}
              color="primary"
            >
              <span>Adicionar</span>
              <span>{cash(total)}</span>
            </Button>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Item;
