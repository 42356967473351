import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  height: 100px;
  /* justify-content: space-between; */
  flex-direction: column;
  justify-content: center;
  padding: 10px !important;
  margin-bottom: 250px;

  button {
    display: flex;
    position: relative;
    left: 0;
    margin-top: 5px;
    padding: 10px 0px;
    bottom: 0;
    flex-direction: row;
  }
`;

export const ButtonConfirm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* background-color: #fffafa !important; */
`;

export const ButtonCancel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const TextNotificationContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
`;

export const TextNotification = styled.span`
  font-size: 18px;
`;
